
window.document.mobileCatalogMenu = (type) => {
  document.getElementById('header-menu-catalog-mobile-' + type).style.opacity = '1.0'
  document.getElementById('header-menu-catalog-mobile-' + type).style.display = 'flex'
}

window.document.openMobileCatalogMenu = () => {
  if (document.body.clientWidth > 600) return;

  document.getElementById('header-menu-catalog-mobile').style.opacity = '1.0'
  document.getElementById('header-menu-catalog-mobile').style.display = 'flex'
}

window.document.closeMobileCatalogMenu = () => {

  const menus = document.querySelectorAll('.header__mobile-catalog')

  menus.forEach(menu => {
    menu.style.opacity = '0.0'
    menu.style.display = 'none'
  })
}

// 

window.document.openMobileMenu = () => {
  document.getElementById('header-menu-mobile').style.opacity = '1.0'
  document.getElementById('header-menu-mobile').style.display = 'flex'
}

window.document.closeMobileMenu = () => {

  document.getElementById('header-menu-mobile').style.opacity = '0.0'
  document.getElementById('header-menu-mobile').style.display = 'none'
}

window.document.mobileMenuOutsideClick = (e) => {
  if (e.target != e.currentTarget) return;

  event.currentTarget.style.opacity = '0.0'
  event.currentTarget.style.display = 'none'
}