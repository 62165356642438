import '../styles/index.scss'
import '@splidejs/splide/css'

// import 'bootstrap/dist/js/bootstrap.bundle'

import './utils/sidebar'
import { showPopup } from './utils/popup'

import './header.js'
import './help-block.js'

import {
  updateCartItemsCount,
} from './utils/cart'

window.document.addEventListener('DOMContentLoaded', () => {
  updateCartItemsCount()

})


var lastScrollTop = 0

window.addEventListener('scroll', function() {
  var st = window.pageYOffset || document.documentElement.scrollTop
  
  if (st < 200 || st > lastScrollTop) {
    document.getElementById('header-fixed').style.display = 'none'

  } else if (st < lastScrollTop) {
    document.getElementById('header-fixed').style.display = 'flex'
    
  }
  lastScrollTop = st <= 0 ? 0 : st

}, false)


window.document.openSizeChartPopup = (e) => {

  showPopup('size-chart', {
    itemPK: e.currentTarget.dataset.itemPk,
  })
  
}