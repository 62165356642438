import {
  getCartData,
} from './utils/cart'

import { showNotification } from './utils/notification'
import { getCookie } from './utils/fetch'

import { createPhoneMask } from './utils/masks'


const helpPhoneInput = document.getElementById('phone-help-i')
var helpPhoneMask = null

window.document.addEventListener('DOMContentLoaded', () => {

  helpPhoneMask = createPhoneMask(helpPhoneInput)

})

window.document.helpBlockButtonClick = () => {

  if (helpPhoneInput.value.trim() == '' || (helpPhoneMask != null && helpPhoneMask.unmaskedValue.length < 11)) {

    showNotification({ text: 'Пожалуйста введите телефон', type: 'info' })

    return;
  }

  const cartData = getCartData()

  //

  window.ym(46695258, 'reachGoal', 'goal-left-phone-for-help')

  window.gtag('event', 'goal-left-phone-for-help', {})

  window.VK.Retargeting.Event('lid_place-a-phone')
  
  //

  fetch('/api/v1/leads/new/', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'X-CSRFToken': getCookie('csrftoken'),
    },
    body: JSON.stringify({
      type: 2,
      phone: helpPhoneMask.unmaskedValue,
      cart: cartData,
    })
  })
  .then((response) => {
    return response.json()
  })
  .then((data) => {
    if (data.status == 'ok') {
      console.log('ok')
    }
  })

  document.getElementById('help-block-inputs-container').innerHTML = 'Мы получили вашу заявку!'

}